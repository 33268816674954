export function nobelFetch(elm, targetElm) {
    return elm.querySelector(targetElm);
}

export function nobelFetchAll(elm, targetElm) {
    return elm.querySelectorAll(targetElm);
}

export function addClass(elm, newClass) {
    return elm.classList.add(newClass);
}

export function removeClass(elm, newClass) {
    return elm.classList.remove(newClass);
}

export function getAttr(elm, element) {
    return elm.getAttribute(element)
}