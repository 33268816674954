
import { nobelFetch, nobelFetchAll, addClass, removeClass, getAttr } from "./module";

/**
 * Dropdown
 */

// function nobelDropdown() {
//     const dropdown = nobelFetchAll(document, '.nobel-dropdown');
//     console.log('checking')

//     const hoverEffect = (list) => {
//         const menu = nobelFetch(list, '.nobel-dropdown--menu')
//         removeClass(menu, 'invisible')
//         addClass(menu, 'visible')
//         menu.style.maxHeight = menu.scrollHeight + 'px';
//         menu.style.transition = `all 300ms ease-in-out 0s`;
//     }
    
//     const constantEffect = (list) => {
//         const menu = nobelFetch(list, '.nobel-dropdown--menu')
        
//         removeClass(menu, 'visible')
//         addClass(menu, 'invisible')
//         menu.style.transition = `all 300ms ease-in-out 0s`;
//     }
    
//     dropdown.forEach((elm) => {
//         const menu = nobelFetch(elm, '.nobel-dropdown--menu');
        
//         addClass(menu, 'invisible');
//         menu.style.willChange = 'max-height';
//         menu.style.maxHeight = 0+'px';
//         menu.style.transition = `all 300ms ease-in-out 0s`;

//         elm.onmouseenter = () => {
//             // if(menu.style.maxHeight === 0+'px') {
//                 hoverEffect(elm)
//             // }
//         }

//         elm.onmouseout = () => {
//             // if(menu.style.maxHeight !== 0+'px') {
//                 constantEffect(elm)
//             // }
//         }

//     })
// }

/**
 * Nobel Modal
 */
function nobelModalScope() {
    const triggerButtons = nobelFetchAll(document, '.nobel-modal--open-btn');
    const nobelModals = nobelFetchAll(document, '.nobel-modal');
    const nobelMainBody = nobelFetch(document, 'body');

    const openModal = (modal) => {
        addClass(modal, 'nobel-modal--open')
        addClass(nobelMainBody, 'nobel-modal--is-active')
        removeClass(modal, 'nobel-modal--close')
        modal.style.maxHeight = '100vh'
        modal.style.zIndex = '1000'
    }

    const closeModal = (modal) => {
        addClass(modal, 'nobel-modal--close')
        removeClass(nobelMainBody, 'nobel-modal--is-active')
        removeClass(modal, 'nobel-modal--open')
        modal.style.maxHeight = null
        modal.style.zIndex = '0'
    }

    triggerButtons.forEach((button) => {
        button.onClick = ($openModal) => {
            const modalId = nobelFetch(document, getAttr($openModal.target, 'data-modal'))
            openModal(modalId)
        }
    })

    window.addEventListener('load', ($openModal) => {
        const modalId = nobelFetch(document, '#nobel-modal-notification')
        openModal(modalId)
    })

    nobelModals.forEach((modal) => {
        const closeModalButton = nobelFetch(modal, '.nobel-modal__close-btn')
        const dialog = nobelFetch(modal, '.nobel-modal__dialog')
        
        closeModalButton.addEventListener('click', () => {
            const closingModal = nobelFetch(document, getAttr(closeModalButton, 'data-modal'))
            closeModal(closingModal);

        })

        if(modal.dataset.maxwidth) {
            dialog.style.maxWidth = modal.dataset.maxwidth;
        }
        else {
            dialog.style.maxWidth = null;
        }
    })
}

/**
 * Slide To Top Script
 */
function nobel__slideToTopScope () {
    let nobel__slideBtn = nobelFetch(document, '#nobel_slide_to_top');
    let footerSection = nobelFetch(document, '.nobel-footer');
    let topPos = footerSection.offsetTop;
    let scrollPos = 0
    // nobel__slideBtn.style.display = 'none';
    // console.log(scrollPos)
       
    function checkPosition() {
        if(window.scrollY > topPos)    {
            nobel__slideBtn.style.display = 'block';
        }
        else {
            nobel__slideBtn.style.display = 'none';
        }
    }
    

    nobel__slideBtn.onclick = () => {
        console.log('click')
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
}

/**
 * ClallBack
 */

function ClallBack() {
    // nobelDropdown();
    nobelModalScope();
    nobel__slideToTopScope();
}

ClallBack();